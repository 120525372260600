import React from 'react'
import './banner.less'

export default ()=>{
    return(
        <>
            <article className="banner">
                <p>
                    Notice: Alex Yadgarov & Associates are continuing to operate with the health and safety of our employees and clients as top priority. We want you to rest assured that we are working at full capacity and are ready to speak with you. Please don’t hesitate to call us if you have any questions!
                </p>
            </article>
        </>
    )
}
