import CarIcon from '../../images/car-accident/CarAccident.svg';
import BusIcon from '../../images/car-accident/BusAccident.svg';
import ConstructionIcon from '../../images/car-accident/ConstructionAccident.svg';
import ScaffoldIcon from '../../images/car-accident/ScaffoldAccident.svg';
import AutomobileIcon from '../../images/car-accident/AutomobileAccident.svg';
import SemiTruckIcon from '../../images/car-accident/Semi-TruckAccident.svg';
import TrainIcon from '../../images/car-accident/TrainAccident.svg';
import TruckIcon from '../../images/car-accident/TruckAccident.svg';

export default [
    {
        practice:"Car Accident Injury",
        icon:CarIcon,
        winnings:"$1,100,000"
    },
    {
        practice:"Automobile Accident Injury",
        icon:AutomobileIcon,
        winnings:"$850,000"
    },
    {
        practice:"Truck Accident Injury",
        icon:TruckIcon,
        winnings:"$2,250,000"
    },
    {
        practice:"Scaffold Accident Injury",
        icon:ScaffoldIcon,
        winnings:"$2,900,000"
    },
    {
        practice:"Semi-Truck Accident Injury",
        icon:SemiTruckIcon,
        winnings:"$2,250,000"
    },
    {
        practice:"Bus Accident Injury",
        icon:BusIcon,
        winnings:"$950,000"
    },
    {
        practice:"Train Accident Injury",
        icon:TrainIcon,
        winnings:"$1,640,000"
    },
    {
        practice:"Construction Accident Injury",
        icon:ConstructionIcon,
        winnings:"$4,750,000"
    },
]