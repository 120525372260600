import React from "react";
import {BsFillShieldLockFill} from 'react-icons/bs'
import {IoIosLock} from 'react-icons/io'
import {useState} from 'react';
import {FaPhoneAlt} from "react-icons/fa"
import './consult.less'


export default ({btnID})=>{
    const [injury, setInjury] = useState('');

    const logEvent = (e) => {
        console.log(e.currentTarget.id);
    }

    return(
        <form action="/success/" method="post" name="car-accident-consultation-form" data-netlify="true" id='form-one' className="hero-form">
            <input type="hidden" name="Car Accident Consultation Form" value="car-accident-consultation-form" />
            <h2>Free In-Depth, No Obligation Case Evaluation</h2>
            <div className="hero-form">
                {/* Single Input */}
                <input 
                    type="text" 
                    name="Full Name" 
                    placeholder="Full Name" 
                    className="form-control"
                    required
                />
                {/* End Of Single Input */}
                            
                {/* Single Input */}
                <input 
                    type="text" 
                    name="phone" 
                    placeholder="Phone" 
                    className="form-control half"
                    required
                />
                {/* End Of Single Input */}
                            
                {/* Single Input */}
                <input 
                    type="email" 
                    name="Email" 
                    placeholder="Email" 
                    className="form-control half"
                    required
                />
                {/* End Of Single Input */}
                
                {/* Single Input */}
                <article>
                    <select name="Injuries" id="injuries" placeholder="Injury Type" className={`form-control ${injury? "has-value" : "has-no-value"}`}
                    value={injury}
                    onChange = {e => {setInjury(e.target.value)}
                    }
                    required
                    >
                        <option value=""></option>
                        <option value="car">Car Accident</option>
                        {/* <option value="slip & fall">Slip & Fall</option> */}
                        {/* <option value="worker injury">Work Related Injury</option> */}
                        {/* <option value="product">Product Liability</option> */}
                        <option value="bus">Bus Accident</option>
                        <option value="train">Train Accident</option>
                        {/* <option value="scaffold">Scaffold Accident</option> */}
                        {/* <option value="construction">Construction Accident</option> */}
                        <option value="truck">Truck Accident</option>
                        <option value="automobile">Automobile Accident</option>
                        {/* <option value="fire/explosion/burn">Fire/Explosion/Burn Accident</option> */}
                        {/* <option value="medicaid">Medicaid Fraud</option> */}
                        {/* <option value="malpractice">Medical Malpractice</option> */}
                        {/* <option value="wrongful death">Wrongful Death</option> */}
                        {/* <option value="9/11">9/11 Related Illness</option> */}
                    </select>
                    <label htmlFor="injuries">Select Injury Type</label>
                </article>
                {/* End Of Single Input */}
                        
                {/* Single Input */}
                    <textarea 
                        name="Message" 
                        cols="30" rows="10" 
                        placeholder="Message"  
                        className="form-control textarea">
                    </textarea>
                {/* End Of Single Input */}

            <button type="submit" className="form-btn" id={`${btnID === 1 ? 'FreeConsultForm' : 'HeroForm '}`} onClick={(e)=>{logEvent(e)}}><IoIosLock className="lock-icon" /> Claim Your Free Consult Now</button>
            <a href="tel:718-276-2800" className="btn"><span><FaPhoneAlt /></span> 718-276-2800</a>
            </div>
        </form>
    )
}
